import React, { useState, useEffect } from "react";
import { Card, Pagination } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import * as API from "../../services/env";

export default function NotificationScreen() {
  const [Datas, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Number of items per page

  // Logic to calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Datas.slice(indexOfFirstItem, indexOfLastItem);

  // Change page
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const calculateRelativeTime = (createdAt) => {
    const now = new Date();
    const createdDate = new Date(createdAt);
    const diffMs = now - createdDate;

    const seconds = Math.floor(diffMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
  };

  const onFetchNotificationTable = async () => {
    try {
      const userData = localStorage.getItem("userDetail");
      if (!userData) return;
      const data = JSON.parse(userData);

      const response = await fetch(`${API.BASE_URL}notificationTable`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          // token: data.authentication_token,
        }),
      });
      const res = await response.json();
      if (res.status) {
        setData(res.supportTable);
      } else if (res.msg) {
        // Handle error or display a message
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    onFetchNotificationTable();
  }, []);

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-4">
          <h5>Notifications</h5>
        </Card.Header>
        <Card.Body>
          {currentItems.map((item, index) => (
            <div key={index} className="notificationlist mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h2>{item?.title}</h2>
                <span className="notifytime">
                  <Icon icon="tabler:clock" />
                  {calculateRelativeTime(item.createdAt)}
                </span>
              </div>
              <p>{item.messgage}</p>
            </div>
          ))}
          <Pagination>
            {Array.from({ length: Math.ceil(Datas.length / itemsPerPage) }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => handlePageChange(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
          </Pagination>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
