import React, { useEffect,useState } from "react";
import { Card, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import avatarimg1 from '../assets/img/userlogo1.png'

import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";

export default function Viewuser() {
  const [data, setdata] = useState("");

  const [loader, setloader] = useState("");


  const fetchUserParticularDeatl = async (id) => {
    const userData = localStorage.getItem("userDetail");
    setloader(true)
    if (userData) {
      var data = JSON.parse(userData);

      let payload = {
        token: data.authentication_token,
        userId: id,
      }
      // console.log('payload',payload);
    }
    // console.log('id',id);

    await fetch(`${API.BASE_URL}AdminFetchUserParDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
        userId: id,

        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {

    setloader(false)

        if (res.status == true) {
          setdata(res.userdeatil[0]);
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };


// useEffect(()=>{
//   fetchUserParticularDeatl()
// })
useEffect(() => {
  const currentUrl = window.location.href;

  // Split the URL by '/'
  const urlParts = currentUrl.split("/");

  // Get the last part of the URL, which is the user ID
  const lastPart = urlParts[urlParts.length - 1];
  fetchUserParticularDeatl(lastPart);

  // console.log("lastPart", lastPart);
}, []);

  const userinfo = {
    name: 'Kinetic Shipping Co.',
    Type: 'Trading Shipping',
    email: 'kineticshipping@gmail.com',
    image: avatarimg1,
    phone: '+91 8053667426',
    taxid: '996GJK4545K',
    address: 'Trading Shipping',
    briefbio: 'Lorem ipsum dolor sit amet consectetur. Tellus pellentesque convallis ultricies risus mi est ultrices. Duis risus suspendisse mauris est dui. Ornare ut vitae magna suscipit tortor nullam urna. Donec libero quam risus volutpat varius tempor nisl molestie facilisi.',
  };





  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>User Details</h5>
          <Link className="btn btn-primary d-inline-flex align-items-center px-4 min_width140" to="/Users">Back</Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <Table responsive>


          {loader == true ? (
                <Oval
                  height="250"
                  width="30"
                  radius="30"
                  color="#056AEC"
                  ariaLabel="loading"
                  wrapperStyle={{ marginLeft: 500 }}
                  wrapperClass
                />
              ) : (
                <tbody>
              <tr>
                <td><b>Image</b></td>
                <td>
                <img className="dtlimg img-thumbnail" src={`${PIC.PIC_URL}${data.pic}`} /></td>
              </tr>
              <tr>
                <td><b>Organization Name</b></td>
                <td>#{data.organisationName?data.organisationName:'N/A'}</td>
              </tr>
              <tr>
                <td><b>Organization Type</b></td>
                <td>#{data.Organization_Type?data.Organization_Type:"N/A"}</td>
              </tr>
              <tr>
                <td><b>Email Address</b></td>
                <td>{data.email?data.email:'N/A'}</td>
              </tr>
              <tr>
                <td><b>Phone No.</b></td>
                <td>{data.Phone_Number?data.Phone_Number:'N/A'}</td>
              </tr>
              <tr>
                <td><b>TAX ID</b></td>
                <td>{data.TaxId?data.TaxId:'N/A'}</td>
              </tr>
              <tr>
                <td><b>Address</b></td>
                <td>{data.Address?data.Address:'N/A'}</td>
              </tr>
              <tr>
                <td><b>Brief Bio</b></td>
                <td>{data.Brief_Bio?data.Brief_Bio:'N/A'}</td>
              </tr>
            </tbody>
              )}



           
          </Table>
        </Card.Body>
      </Card>
    </React.Fragment>
  );

}