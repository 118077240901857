import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';

import * as API from "../../services/env";
import * as PIC from "../../services/env";
import moment from 'moment'; 
import { Oval } from "react-loader-spinner";
export default function Screenings() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [idShow, setidShow] = useState(false);

  const handleShow = (id) => {
    setShow(true); 
    setidShow(id)
   
  };
  const columns = [
    {
      name: 'Screening ID',
      minWidth: "180px",
      selector: row => row.screeningId,
      sortable: true,
      cell: (row) =>
      <>
        <Link  to={`/Viewscreening/${row._id}`}>{row.screeningId}</Link>
      </>     
    },

    {
      name: 'reference no',
      minWidth: "150px",
      selector: row => row.referenceNo,
      sortable: true,
      cell: (row) => row.referenceNo
    },
    {
      name: 'Screening Type',
      minWidth: "150px",
      selector: row => row.screeningType,
      sortable: true,
      cell: (row) => row.screeningType
    },
    {
      name: 'User',
      minWidth: "180px",
      selector: row => row.userData.length>0?row.userData[0].email:'N/A',
      sortable: true,
      cell: (row) => row.userData.length>0?row.userData[0].email:'N/A'
    },
    {
      name: 'Date',
      minWidth: "180px",
      selector: row => moment(row.createdAt).format('LL'),
      sortable: true,
      cell: (row) => moment(row.createdAt).format('LL'),
    },
    {
      name: 'Actions',
      cell: row => (
        <>
         <Link className="btndelete"  onClick={()=>handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data, setData] = useState([
  
  ]);

  const [loader, setloader] = useState("");


  // Filter data based on search text
  const filteredData = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) !== -1
  );



  const fetechAdminScreenigdeatil = async (id) => {
    const userData = localStorage.getItem("userDetail");
    setloader(true)
    if (userData) {
      var data = JSON.parse(userData);

      let payload = {
        token: data.authentication_token,
        userId: id,
      }
      // console.log('payload',payload);
    }
    // console.log('id',id);

    await fetch(`${API.BASE_URL}adminGetScreeningUserDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
      

        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {

    setloader(false)


    console.log('res',res);
        if (res.status == true) {
          setData(res.data);
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };


  const ScreeningDelete = async (id)=>{

    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);


    }


    let payload = {
      token: data.authentication_token,
      screeningId: idShow,
    }
    console.log('payload',payload);


     await fetch(`${API.BASE_URL}adminDelteScreenig`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
        screeningId: idShow,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setShow(false)
          window.location.reload()
        } 
      })
      .catch((error) => {});



  }






useEffect(()=>{
  fetechAdminScreenigdeatil()
},[])




  return (
    <React.Fragment>


{loader == true ? (
        <Oval
          height="30"
          width="30"
          radius="30"
          color="#f2f5f3"
          ariaLabel="loading"
          wrapperStyle={{ marginLeft: 500, marginTop: 150 }}
          wrapperClass
        />
      ) :(
        <> <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="py-4">
              <h5>Screenings</h5>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-secondary px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={ScreeningDelete }>
            Ok
          </Button>
        </Modal.Body>
      </Modal></>
      )}

     
    </React.Fragment>
  );
}
