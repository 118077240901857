import React, { useState, useEffect } from "react";
import "./themes/main.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from "./components/pages/Login";
import ForgotPassword from "./components/pages/ForgotPassword";
import ResetPassword from "./components/pages/ResetPassword";
import OTP from "./components/pages/OTP";
import Layout from './components/layout/Layout';
import Dashboard from "./components/pages/Dashboard";
import Users from "./components/pages/Users";
import Adduser from "./components/pages/Adduser";
import Edituser from "./components/pages/Edituser";
import Viewuser from "./components/pages/Viewuser";
import Contactqueries from "./components/pages/Contactqueries";
import ProfileSettings from "./components/pages/ProfileSettings";
import Notifications from "./components/pages/Notifications";
import Subscriptions from "./components/pages/Subscriptions";
import Editsubscription from "./components/pages/Editsubscription";
import Viewscreening from "./components/pages/Viewscreening";
import NotificationScreen from "./components/pages/NotificationScreen";

import Screenings from "./components/pages/Screenings";
import { Sentry } from "react-activity";
import "react-activity/dist/library.css";
import 'bootstrap/dist/css/bootstrap.min.css';
function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
    {loading ? (
      <div className="loaderdiv">
        <Sentry color="#056AEC" size={40} speed={1} animating={true} />
      </div>
    ) : (
      <Router > 
      {/* <Router>  */}
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/ForgotPassword" element={<ForgotPassword />} />
          <Route path="/OTP" element={<OTP />} />
          <Route path="/ResetPassword/:id" element={<ResetPassword />} />
          <Route path="/Dashboard" element={<Layout title={"Dashboard"}><Dashboard /></Layout>} />
          <Route path="/Users" element={<Layout title={"Users"}><Users /></Layout>} />
          <Route path="/Adduser" element={<Layout title={"Add User"}><Adduser /></Layout>} />
          <Route path="/Edituser/:id" element={<Layout title={"Edit User"}><Edituser /></Layout>} />
          <Route path="/Viewuser/:id" element={<Layout title={"User Details"}><Viewuser/></Layout>} />
          <Route path="/Contactqueries" element={<Layout title={"Contact Queries"}><Contactqueries /></Layout>} />
          <Route path="/Notifications" element={<Layout title={"Notifications"}><Notifications /></Layout>} />
          <Route path="/Subscriptions" element={<Layout title={"Subscriptions"}><Subscriptions /></Layout>} />
          <Route path="/Editsubscription/:id" element={<Layout title={"Edit Subscription"}><Editsubscription /></Layout>} />
          <Route path="/Screenings" element={<Layout title={"Screenings"}><Screenings /></Layout>} />
          <Route path="/Viewscreening/:id" element={<Layout title={"Screening Details"}><Viewscreening /></Layout>} />
          <Route path="/ProfileSettings" element={<Layout title={"Profile Settings"}><ProfileSettings /></Layout>} />
          <Route path="/NotificationScreen" element={<Layout title={"Notifications"}><NotificationScreen /></Layout>} />

        </Routes>
      </Router>
    )}
  </>
  );
}

export default App;
