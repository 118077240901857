import React, { useState, useEffect } from "react";
import { Card, Pagination, Form, Button, Dropdown } from "react-bootstrap";
import { Icon } from "@iconify/react";

import { logDOM } from "@testing-library/react";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Notifications() {
  const [Datas, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [title, settitle] = useState("");

  const [titleErr, settitleErr] = useState("");

  const [subject, setSubject] = useState("");

  const [subjectErr, setsubjectErr] = useState("");

  const [body, setBody] = useState("");

  const [bodyErr, setbodyErr] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [errorCount, seterrCount] = useState(0);

  const [loader, setloader] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  // const [selectAll, setSelectAll] = useState(false);
  const [email, setemail] = useState([]);
  const itemsPerPage = 5; // Number of items per page

  // Logic to calculate the current items to display based on pagination
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = Datas.slice(indexOfFirstItem, indexOfLastItem);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (SERVICE.hasError(title)) {
      setloader(false);
      errorCount++;
      let msg = "Please enter your title.";
      settitleErr(msg);
    } else {
      settitleErr(null);
    }

    // if (SERVICE.hasError(subject)) {
    //   errorCount++;
    //   let msg = "Please enter your subject.";
    //   setsubjectErr(msg);
    // } else {
    //   setsubjectErr(null);
    // }

    if (SERVICE.hasError(body)) {
      errorCount++;
      setloader(false);
      let msg = "Please enter your message.";
      setbodyErr(msg);
    } else {
      setbodyErr(null);
    }

 

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const [selectedEmails, setSelectedEmails] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedId, setselectedId] = useState([]);

  const handleEmailSelection = (selectedEmail, id) => {
    // console.log("emailid", id);
    // const isAlreadySelected = selectedEmails.includes(selectedEmail);
    // setSelectedEmails((prevSelectedEmails) => {
    //   if (!isAlreadySelected) {
    //     return [...prevSelectedEmails, selectedEmail];
    //   } else {
    //     return prevSelectedEmails.filter((email) => email !== selectedEmail);
    //   }
    // });
    setSelectedEmails(selectedEmail)

    setselectedId([id])
    // const isAlreadySelectedId = selectedId.includes(id);
    // console.log("isAlreadySelectedIdsss", isAlreadySelectedId);

    // setselectedId((prevSelectedId) => {
    //   if (!isAlreadySelectedId) {
    //     console.log("isAlreadySelectedIds", isAlreadySelectedId);
    //     return [...prevSelectedId, id];
    //   } else {
    //     return prevSelectedId.filter((ids) => ids !== id);
    //   }
    // });
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedEmails([]);
    } else {
      const allEmails = email.map((user) => user.email);
      setSelectedEmails(allEmails);
    }
    setSelectAll(!selectAll);
  };

  const handleSendNotification = async () => {
    try {
      setloader(true);
      setSubmit(true);

      // console.log("Bio", Bio);
    if (selectedId.length==0) {
      setloader(false);

      return toast('Please select user')
    }

      onProfileValidation();
      if (errorCount > 0) return;
      const userData = localStorage.getItem("userDetail");
      if (!userData) return;
      const data = JSON.parse(userData);

      let payload = {
        userId: [selectedId],
        message: body,
        title: title,
      };

      const response = await fetch(`${API.BASE_URL}adminNotification`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          userId: [selectedId],
          message: body,
          title: title,
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          console.log("res", res);
          setloader(false);

          if (res.success == true) {
            toast("Notification send successfully");
          }
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    // Logic to send notification to selected emails
  };

  // const handleSendNotification = async (selectedEmail) => {
  //   // Logic to send notification to selected users
  //   // You can implement this based on your backend API

  //   console.log("selectedEmail", selectedEmail);
  //   const isAlreadySelected = selectedUsers.includes(selectedEmail);
  //   if (!isAlreadySelected) {
  //     setSelectedUsers([...selectedUsers, selectedEmail]);
  //   } else {
  //     setSelectedUsers(
  //       selectedUsers.filter((email) => email !== selectedEmail)
  //     );
  //   }
  //   // Clear form and selections after sending
  //   setShowForm(false);
  //   setTo("");
  //   setSubject("");
  //   setBody("");
  //   setSelectedUsers([]);
  //   setSelectAll(false);
  // };

  const onUserFetchDeatil = async () => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
    await fetch(`${API.BASE_URL}AdminFetchUserDeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log("resres", res);
        if (res.status == true) {
          setemail(res.userdeatil);
        } else if (res.msg) {
          // toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    onUserFetchDeatil();
  }, []);

  useEffect(() => {
    onProfileValidation();
  }, [title, body]);


  console.log('selectAll',selectedEmails);
  return (
    <React.Fragment>
      {/* <Card>
        <Card.Header className="py-4 d-flex justify-content-between">
          <h5>Notifications</h5>
          <Button onClick={() => setShowForm(true)}>
            Send Notification
          </Button>
        </Card.Header>
        <Card.Body>
          {currentItems.map((item, index) => (
            <div key={index} className="notificationlist mb-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <h2>{item?.title}</h2>
                <span className="notifytime">
                  <Icon icon="tabler:clock" />
                  {calculateRelativeTime(item.createdAt)}
                </span>
              </div>
              <p>{item.messgage}</p>
            </div>
          ))}
          <Pagination>
            {Array.from(
              { length: Math.ceil(Datas.length / itemsPerPage) },
              (_, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              )
            )}
          </Pagination>
        </Card.Body>
      </Card> */}

      {/* {showForm && ( */}
      <Card className="mt-4">
        <Card.Body>
          <Form>
            <Form.Group controlId="formto">
              <Form.Label>Tittle:</Form.Label>
              <Form.Control
                type="text"
                value={title}
                onChange={(e) => settitle(e.target.value)}
              />

              {titleErr && Submit ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {titleErr}
                </div>
              ) : null}
            </Form.Group>

            {/* <Form.Group controlId="formSubject">
                <Form.Label>Subject:</Form.Label>
                <Form.Control
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
              </Form.Group> */}
            <Form.Group controlId="formBody" className="mt-4">
              <Form.Label>Body:</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={body}
                onChange={(e) => setBody(e.target.value)}
              />
              {bodyErr && Submit ? (
                <div
                  style={{
                    fontFamily: "AvenirLTProLight",
                    color: "#FF0000",
                    opacity: 0.6,
                  }}
                >
                  {bodyErr}
                </div>
              ) : null}
            </Form.Group>
            <Form.Group controlId="formUsers" className="dropdownCustom">
            <Form.Label>Select User:</Form.Label>
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-emails">
                  {selectAll ? "All Users Selected" : selectedEmails}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleSelectAll}>
                    {selectAll ? "Unselect All" : "Select All"}
                  </Dropdown.Item>
                  {email.map((user, index) => (
                    <Dropdown.Item
                      key={index}
                      onClick={() => handleEmailSelection(user.email, user._id)}
                    >
                      {user.email}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Form.Group>

            {loader == true ? (
              <Oval
                height="30"
                width="30"
                radius="30"
                color="#f2f5f3"
                ariaLabel="loading"
                wrapperStyle={{ marginLeft: 40,marginTop:20 }}
                wrapperClass
              />
            ) : (
              <Button
                className="btn btn-primary px-4 mt-3 min_width140"
                onClick={(e) => {
                  handleSendNotification();
                }}
              >
                Send Notification
              </Button>
            )}
            <ToastContainer />
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}

// import React, { useState, useEffect } from "react";
// import { Card, Pagination } from 'react-bootstrap';
// import { Icon } from '@iconify/react';
// import * as API from "../../services/env";

// export default function Notifications() {
//   const [Datas, setData] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const itemsPerPage = 5; // Number of items per page

//   // Logic to calculate the current items to display based on pagination
//   const indexOfLastItem = currentPage * itemsPerPage;
//   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
//   const currentItems = Datas.slice(indexOfFirstItem, indexOfLastItem);

//   // Change page
//   const handlePageChange = (page) => {
//     setCurrentPage(page);
//   };

//   const calculateRelativeTime = (createdAt) => {
//     const now = new Date();
//     const createdDate = new Date(createdAt);
//     const diffMs = now - createdDate;

//     const seconds = Math.floor(diffMs / 1000);
//     const minutes = Math.floor(seconds / 60);
//     const hours = Math.floor(minutes / 60);
//     const days = Math.floor(hours / 24);

//     if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
//     if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
//     if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
//     return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
//   };

//   const onFetchNotificationTable = async () => {
//     try {
//       const userData = localStorage.getItem("userDetail");
//       if (!userData) return;
//       const data = JSON.parse(userData);

//       const response = await fetch(`${API.BASE_URL}notificationTable`, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Accept: "application/json",
//         },
//         body: JSON.stringify({
//           // token: data.authentication_token,
//         }),
//       });
//       const res = await response.json();
//       if (res.status) {
//         setData(res.supportTable);
//       } else if (res.msg) {
//         // Handle error or display a message
//       }
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => {
//     onFetchNotificationTable();
//   }, []);

//   return (
//     <React.Fragment>
//       <Card>
//         <Card.Header className="py-4">
//           <h5>Notifications</h5>
//         </Card.Header>
//         <Card.Body>
//           {currentItems.map((item, index) => (
//             <div key={index} className="notificationlist mb-3">
//               <div className="d-flex align-items-center justify-content-between mb-2">
//                 <h2>{item?.title}</h2>
//                 <span className="notifytime">
//                   <Icon icon="tabler:clock" />
//                   {calculateRelativeTime(item.createdAt)}
//                 </span>
//               </div>
//               <p>{item.messgage}</p>
//             </div>
//           ))}
//           <Pagination>
//             {Array.from({ length: Math.ceil(Datas.length / itemsPerPage) }, (_, i) => (
//               <Pagination.Item
//                 key={i + 1}
//                 active={i + 1 === currentPage}
//                 onClick={() => handlePageChange(i + 1)}
//               >
//                 {i + 1}
//               </Pagination.Item>
//             ))}
//           </Pagination>
//         </Card.Body>
//       </Card>
//     </React.Fragment>
//   );
// }
