import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  Modal
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link } from 'react-router-dom';
import userlogo1 from '../assets/img/userlogo1.png'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";
export default function Users() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState('');
  const [show, setShow] = useState(false);

  const [idShow, setidShow] = useState(false);

  const [loader, setloader] = useState(false);


  const handleClose = () => setShow(false);
  // const handleShow = (id) => setShow(true);

  const handleShow = (id) => {
    setShow(true); 
    setidShow(id)
   
  };
  const [data,setData]= useState([])

  const columns = [
    {
      name: "User Profile",
      selector: (row) => row.pic,
      sortable: true,
      cell: (row) => (
        <>
        {row.pic?<img
            className="table_img rounded-circle img-thumbnail me-2"
            src={`${PIC.PIC_URL}${row.pic}`}
          />:""}
          
        </>
      ),
    },
    {
      name: "Organization Name",
      selector: (row) => row.organisationName?row.organisationName:'N/A',
      sortable: true,
      
    },
    {
      name: "Organization Type",
      selector: (row) => row.Organization_Type?row.Organization_Type:'N/A',
      sortable: true,
      cell: (row) => row.Organization_Type?row.Organization_Type:'N/A',
    },
    {
      name: "Email",
      selector: (row) => row.email?row.email:'N/A',
      sortable: true,
      cell: (row) => row.email?row.email:'N/A',
    },
    {
      name: "Phone No.",
      selector: (row) => row.Phone_Number?row.Phone_Number:'N/A',
      sortable: true,
      cell: (row) => row.Phone_Number?row.Phone_Number:'N/A' ,
    },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btnview" to={`/Viewuser/${row._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={`/Edituser/${row._id}`}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete"  onClick={()=>handleShow(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  // const [data, setData] = useState([
  //   { 
  //     id: 1, 
  //     name: 'Kinetic Shipping Co.', 
  //     imgurl: userlogo1,
  //     email: 'kineticshipping@gmail.com', 
  //     phone: '+91 8053667426', 
  //     Type: 'Trading Shipping', 
  //   },
  //   { 
  //     id: 2, 
  //     name: 'Kinetic Shipping Co.', 
  //     imgurl: userlogo1,
  //     email: 'kineticshipping@gmail.com', 
  //     phone: '+91 8053667426', 
  //     Type: 'Trading Shipping', 
  //   },
  //   { 
  //     id: 3, 
  //     name: 'Kinetic Shipping Co.', 
  //     imgurl: userlogo1,
  //     email: 'kineticshipping@gmail.com', 
  //     phone: '+91 8053667426', 
  //     Type: 'Trading Shipping', 
  //   },
  //   { 
  //     id: 4, 
  //     name: 'Kinetic Shipping Co.', 
  //     imgurl: userlogo1,
  //     email: 'kineticshipping@gmail.com', 
  //     phone: '+91 8053667426', 
  //     Type: 'Trading Shipping', 
  //   },
  //   { 
  //     id: 5, 
  //     name: 'Kinetic Shipping Co.', 
  //     imgurl: userlogo1,
  //     email: 'kineticshipping@gmail.com', 
  //     phone: '+91 8053667426', 
  //     Type: 'Trading Shipping', 
  //   },
  // ]);

  // Filter data based on search text
  const filteredData = data.filter(
    item =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(searchText.toLowerCase()) !== -1
  );

  const onUserFetchDeatil = async () => {
    setloader(true)
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
    await fetch(`${API.BASE_URL}AdminFetchUserDeatil`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
  
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloader(false)
        if (res.status == true) {
          setData(res.userdeatil)
       
        } else if (res.msg) {
          // toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // onSubmit();
    onUserFetchDeatil()
  }, []);





  const onSubmit = async () => {
  
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);


    }
     await fetch(`${API.BASE_URL}adminDeleteUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
        userId: idShow,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setShow(false)
          window.location.reload()
        } 
      })
      .catch((error) => {});
  };
  // const onSubmit = async () => {

  
  //   await fetch(`${API.BASE_URL}adminDeleteUser`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Accept: "application/json",
  //     },
  //     // mode: "cors",
  //     body: JSON.stringify({
  //       userId: email,
      
  //     }),
  //   })
  //     .then((response) => response.json())
  //     .then(async (res) => {
  //       setloader(false);
  //       if (res.status == true) {
  //         await localStorage.clear();

  //         navigate("/Dashboard");
  //         await localStorage.setItem("IsLogin", true);
  //         await localStorage.setItem("IsLogin", true);

  //         await localStorage.setItem("userDetail", JSON.stringify(res.data));
  //         await localStorage.setItem("token", JSON.stringify(res.token));

  //         await localStorage.setItem("email", JSON.stringify(email));
  //         toast("Welcome To Accel Admin Panel");
  //       } else if (res.msg == "You have provided wrong password") {
  //         toast("You have provided wrong password");
  //       } else if (
  //         res.msg == "Either your domain, email or password is wrong"
  //       ) {
  //         toast("Either your email or password is wrong");
  //       }
  //     })
  //     .catch((error) => {});
  // };
  
  return (
    <React.Fragment>

{loader == true ? (
        <Oval
          height="30"
          width="30"
          radius="30"
          color="#f2f5f3"
          ariaLabel="loading"
          wrapperStyle={{ marginLeft: 500, marginTop: 150 }}
          wrapperClass
        />
      ):(
<> <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Users</h5>
              <Button className="btn btn-primary px-4 min_width140" as={Link} to="/Adduser">Add</Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal className="modal_Delete" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <div className="modaldelete_div">
            <Icon className="delete_icon" icon="gg:close-o"></Icon>
            <h3>Are You Sure ?</h3>
            <p>You will not be able to recover the deleted record!</p>
          </div>
          <Button onClick={handleClose} className="btn btn-secondary px-4 me-3">
            Cancel
          </Button>
          <Button className="btn btn-primary px-4 min_width110" onClick={onSubmit}>
            Ok
          </Button>
        </Modal.Body>
      </Modal></>

      )}
     
    </React.Fragment>
  );
}
