import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Row,
  Col,
  Button ,
} from 'react-bootstrap';
import logo from "../assets/img/logo.svg";
import { Link } from 'react-router-dom';
export default function ForgotPassword() {
  const navigate = useNavigate();
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>OTP Verification</h2>
              <p>Please enter the  6 digit verification code sent on your registered email address.</p>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>OTP</Form.Label>
                <Form.Control type="text" placeholder="Enter your OTP"/>
              </Form.Group>
              <Link to="/ResetPassword"><Button as="input" type="submit" value="Proceed" className="btn btn-primary w-100 mt-3"/></Link>
              <p className="formfooter text-center mt-3">Back To <Link to="/">Login</Link></p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
          <div className="loginlogo_div">
            <Link><img src={logo} alt=""/></Link>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}