import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";

import * as API from "../../services/env";
import * as PIC from "../../services/env";

import { Oval } from "react-loader-spinner";
export default function Subscriptions() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [idShow, setidShow] = useState(false);

  const [loader, setloader] = useState(false);

  const handleShow = (id) => {
    setShow(true);
    setidShow(id);
  };
  const columns = [
    {
      name: "Title",
      minWidth: "180px",
      selector: (row) =>
        row.productDetails && row.productDetails.name == "premium"
          ? "PREMIUM"
          : row.productDetails
          ? row.productDetails.name
          : "",
      sortable: true,
      cell: (row) =>
        row.productDetails && row.productDetails.name == "premium"
          ? "PREMIUM"
          : row.productDetails
          ? row.productDetails.name
          : "",
    },
    {
      name: "Price",
      minWidth: "150px",
      selector: (row) => (row.unit_amount ? `${row.unit_amount / 100}$` : ""),
      sortable: true,
      cell: (row) => (row.unit_amount ? `${row.unit_amount / 100}$` : ""),
    },
    {
      name: "Type",
      minWidth: "180px",
      selector: (row) => (row.recurring ? row.recurring.interval : ""),
      sortable: true,
      cell: (row) => (row.recurring ? row.recurring.interval : ""),
    },
    // {
    //   name: "Description",
    //   minWidth: "350px",
    //   selector: (row) =>  row.productDetails&& row.productDetails.description
    //   ? row.productDetails.description
    //   : "",
    //   sortable: true,
    //   cell: (row) =>  row.productDetails&& row.productDetails.description
    //   ? row.productDetails.description
    //   : "",
    // },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnedit" to={`/Editsubscription/${row.id}`}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleShow(row.id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  const [data, setData] = useState([
    // {
    //   id: 1,
    //   title: "Advance",
    //   price: "$249.00",
    //   type: "Yearly",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 2,
    //   title: "Professional",
    //   price: "$190.00",
    //   type: "Yearly",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
    // {
    //   id: 3,
    //   title: "Basic",
    //   price: "$50.00",
    //   type: "Yearly",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    // },
  ]);

  // Filter data based on search text

  const filteredData = data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(searchText.toLowerCase()) !==
      -1
  );

  const fetchPaymentDetail = async (id) => {
    setloader(true);
    const userData = localStorage.getItem("userDetail");
    // setloader(true);
    if (userData) {
      var data = JSON.parse(userData);

      let payload = {
        token: data.authentication_token,
        userId: id,
      };
      // console.log('payload',payload);
    }
    // console.log('id',id);

    await fetch(`${API.BASE_URL}getPaymnetDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
        userId: id,

        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        // console.log("resresres", res);
        setloader(false);

        if (res.status == true) {
          setData(res.activePricesWithDetails);
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  const onDeactiveSubscription = async () => {
    const userData = localStorage.getItem("userDetail");
    if (userData) {
      var data = JSON.parse(userData);
    }
    await fetch(`${API.BASE_URL}deactiveSubscription`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        priceId: idShow,
      }),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status == true) {
          setShow(false);
          window.location.reload();
        }
      })
      .catch((error) => {});
  };
  useEffect(() => {
    fetchPaymentDetail();

    // console.log("lastPart", lastPart);
  }, []);

  return (
    <React.Fragment>
      {loader == true ? (
        <Oval
          height="30"
          width="30"
          radius="30"
          color="#f2f5f3"
          ariaLabel="loading"
          wrapperStyle={{ marginLeft: 500, marginTop: 150 }}
          wrapperClass
        />
      ) : (
        <>
          <Row>
            <Col lg={12}>
              <Card className="mt-4">
                <Card.Header className="py-4">
                  <h5>Subscriptions</h5>
                </Card.Header>
                <Card.Body>
                  <div className="text-end mb-3">
                    <input
                      type="text"
                      placeholder="Search..."
                      className="searchfield"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    responsive
                    className="custom-table"
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Modal
            className="modal_Delete"
            show={show}
            onHide={handleClose}
            centered
          >
            <Modal.Body>
              <div className="modaldelete_div">
                <Icon className="delete_icon" icon="gg:close-o"></Icon>
                <h3>Are You Sure ?</h3>
                <p>You will not be able to recover the deleted record!</p>
              </div>
              <Button
                onClick={handleClose}
                className="btn btn-secondary px-4 me-3"
              >
                Cancel
              </Button>
              <Button
                className="btn btn-primary px-4 min_width110"
                onClick={onDeactiveSubscription}
              >
                Ok
              </Button>
            </Modal.Body>
          </Modal>
        </>
      )}
    </React.Fragment>
  );
}
