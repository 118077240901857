import React, { useState, useEffect } from "react";
import { Card, Table, Tab, Tabs, Row, Col, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import circle_icon from "../assets/img/icon/circle_icon.svg";
import checkcircle_icon from "../assets/img/icon/checkcircle_icon.svg";
import alertcircle_icon from "../assets/img/icon/alertcircle_icon.svg";
import riskcircle_icon from "../assets/img/icon/riskcircle_icon.svg";
import badgecircle_icon from "../assets/img/icon/badgecircle_icon.svg";

import * as API from "../../services/env";
import * as PIC from "../../services/env";
import moment from "moment";

import { Oval } from "react-loader-spinner";
export default function Viewscreening() {
  const [key, setKey] = useState("");

  const [loader, setloader] = useState("");

  const [data, setdata] = useState("");

  const [countryDeatil, setcountryDeatil] = useState("");

  const fetchScreeningParticularDeatl = async (id) => {
    const userData = localStorage.getItem("userDetail");
    setloader(true);
    if (userData) {
      var data = JSON.parse(userData);

      let payload = {
        token: data.authentication_token,
        ScreenId: id,
      };
      // console.log('payload',payload);
    }
    // console.log('id',id);

    await fetch(`${API.BASE_URL}AdminGetPerScreeningDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: data.authentication_token,
        ScreenId: id,

        // link: `${url}/ResetPassword`,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setloader(false);
        console.log("result", res);
        if (res.status == true) {
          setdata(res.data);
          setcountryDeatil(res.countryDeatil);
        } else if (res.msg) {
          //   toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const urlParts = currentUrl.split("/");

    // Get the last part of the URL, which is the user ID
    const lastPart = urlParts[urlParts.length - 1];
    fetchScreeningParticularDeatl(lastPart);

    // console.log("lastPart", lastPart);
  }, []);

  const checkArray = data.length > 0 ? data[0].check : [];

  console.log("checkArray", checkArray);

  // Define a function to check if a specific type should be open
  // const shouldOpenAccordion = (type) => checkArray.includes(type);

  // // // Usage example:
  // const isOpenConsignee = shouldOpenAccordion("Consignee");
  // const isOpenShipper = shouldOpenAccordion("Shipper");
  // const isOpenEndUser = shouldOpenAccordion("End User");

  const shouldOpenAccordion = (type) => {
    // Check if 'type' is a string or an object with 'info' property
    if (typeof type === "string") {
      return checkArray.includes(type);
    } else if (typeof type === "object" && type.info) {
      return checkArray.some((item) => item.info === type.info);
    }
    return false;
  };

  // Usage example:
  const isOpenConsignee = shouldOpenAccordion("Consignee");
  const isOpenShipperString = shouldOpenAccordion("Shipper");
  const isOpenEndUserString = shouldOpenAccordion("End User");

  const isOpenShipperObject = shouldOpenAccordion({ info: "Shipper" });

  const isOpenConsigneeObject = shouldOpenAccordion({ info: "Consignee" });
  const isOpenEndUserObject = shouldOpenAccordion({ info: "End User" });

  console.log("data", data);

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Screening Details</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Screenings"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body className="bookingtable mt-3">
          <div className="screeingtop_table">
            {!key && (
              <Table responsive>
                <thead>
                  <tr>
                    <th>Screening ID</th>
                    <th>Screening Name</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {data.length > 0 ? (
                    <tr>
                      <td>{data[0].screeningId}</td>
                      <td>{data[0].screeningType}</td>

                      <td> {moment(data[0].createdAt).format("LL")}</td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            )}
          </div>
          <Tabs
            defaultActiveKey="basicinfo"
            id="uncontrolled-tab-example"
            className="customtabs2 mb-2"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="tab1" title="RPS">
              <div className="screenaccordion mt-4">
                <Accordion defaultActiveKey="1">
                  {isOpenShipperString == true ||
                  isOpenShipperObject == true ? (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Shipper</Accordion.Header>
                      <Accordion.Body>
                        <Table bordered>
                          <tbody>
                            {/* {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "Shipper") // Filter by userType "Shipper"
                                .map((item, index) => {

                                  item.name.map((item,indexs)=>{
                                    return (
                                      <tr key={indexs}>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>West Coast Shipping Co.</td>
                                      </tr>
                                    );
                                  })
                                  
                                })} */}
                            {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "Shipper") // Filter by userType "Shipper"
                                .map((item, index) => (
                                  <React.Fragment key={index}>
                                    {Array.isArray(item.name) ? ( // Check if name is an array
                                      item.name.map((nameObject, nameIndex) => (
                                        <tr key={nameIndex}>
                                          <td>
                                            <b>Shipper Name</b>
                                          </td>
                                          <td>
                                            {typeof nameObject === "string"
                                              ? nameObject
                                              : nameObject.name}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      // Handle the case when name is not an array
                                      <tr>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>
                                          {typeof item.name === "string"
                                            ? item.name
                                            : item.name["sdfd"]}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                          </tbody>
                        </Table>

                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          {data.length > 0 &&
                          data[0].screeningType === "Batch Screening" ? (
                            data.map((item, index) => (
                              <React.Fragment key={index}>
                                {item.screeningRpsData
                                  .filter(
                                    (subItem) => subItem.userType === "Shipper"
                                  )
                                  .map((filteredSubItem, subIndex) => (
                                    <Table key={subIndex} bordered>
                                      <tbody>
                                        {filteredSubItem.Owners_name.map(
                                          (owner, ownerIndex) => (
                                            <tr key={ownerIndex}>
                                              {owner.name ? (
                                                <>
                                                  <td>
                                                    <b>Owner Name</b>
                                                  </td>
                                                  <td>{owner.name}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.ShipperRisk ? (
                                                <>
                                                  <td>
                                                    <b>Shipper Risk </b>
                                                  </td>
                                                  <td>{owner.ShipperRisk}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.Shipper_Owners_Address ? (
                                                <>
                                                  <td>
                                                    <b>
                                                      Shipper Owners Address
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {
                                                      owner.Shipper_Owners_Address
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankAddress ? (
                                                <>
                                                  <td>
                                                    <b>Bank Address </b>
                                                  </td>
                                                  <td>{owner.bankAddress}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankCheck ? (
                                                <>
                                                  <td>
                                                    <b>Bank Check</b>
                                                  </td>
                                                  <td>{owner.bankCheck}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankName ? (
                                                <>
                                                  <td>
                                                    <b>Bank Name</b>
                                                  </td>
                                                  <td>{owner.bankName}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperDOBs ? (
                                                <>
                                                  <td>
                                                    <b>Shipper DOB</b>
                                                  </td>
                                                  <td>{owner.shipperDOBs}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperfile ? (
                                                <>
                                                  <td>
                                                    <b>UPload File</b>
                                                  </td>
                                                  <td>
                                                    {owner.shipperfile ? (
                                                      <img
                                                        src={`${PIC.PIC_URL}${owner.shipperfile}`}
                                                        alt="Owner Image"
                                                        style={{
                                                          width: 40,
                                                          height: 40,
                                                        }}
                                                      />
                                                    ) : null}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* Render other properties of owner as needed */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  ))}
                              </React.Fragment>
                            ))
                          ) : (
                            <Table bordered>
                              {data.map((item, index) => (
                                <React.Fragment key={index}>
                                  {item.screeningRpsData
                                    .filter(
                                      (subItem) =>
                                        subItem.userType === "Shipper"
                                    )
                                    .map((filteredSubItem, subIndex) => (
                                      <Table key={subIndex} bordered>
                                        <tbody>
                                          {filteredSubItem.Owners_name.length >
                                          0 ? (
                                            <tr key={subIndex}>
                                              {filteredSubItem.Owners_name ? (
                                                <>
                                                  <td>
                                                    <b>Owner Name</b>
                                                  </td>
                                                  <td>
                                                    {
                                                      filteredSubItem
                                                        .Owners_name[0]
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.repound.some(
                                                (repoundItem) =>
                                                  repoundItem.risk &&
                                                  repoundItem.risk !== ""
                                              ) ? (
                                                filteredSubItem.repound.map(
                                                  (repoundItem, repoundIndex) =>
                                                    // Check if "risk" exists and is not empty in repoundItem
                                                    repoundItem.risk &&
                                                    repoundItem.risk !== "" && (
                                                      <tr key={repoundIndex}>
                                                        <td>
                                                          <b>Shipper Risk</b>
                                                        </td>
                                                        <td>
                                                          {repoundItem.risk}
                                                        </td>
                                                      </tr>
                                                    )
                                                )
                                              ) : (
                                                <tr>
                                                  <td colSpan="2">
                                                    No risks found
                                                  </td>
                                                </tr>
                                              )}

                                              {filteredSubItem.Owners_address ? (
                                                <>
                                                  <td>
                                                    <b>
                                                      Shipper Owners Address
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {
                                                      filteredSubItem.Owners_address
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.bankAddress ? (
                                                <>
                                                  <td>
                                                    <b>Bank Address </b>
                                                  </td>
                                                  <td>
                                                    {
                                                      filteredSubItem.bankAddress
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.bankAddress ? (
                                                <>
                                                  <td>
                                                    <b>Bank Check</b>
                                                  </td>
                                                  <td>{"Yes"}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.bankName ? (
                                                <>
                                                  <td>
                                                    <b>Bank Name</b>
                                                  </td>
                                                  <td>
                                                    {filteredSubItem.bankName}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.shipperDOB ? (
                                                <>
                                                  <td>
                                                    <b>Shipper DOB</b>
                                                  </td>
                                                  <td>
                                                    {filteredSubItem.shipperDOB}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {filteredSubItem.shipperFile ? (
                                                <>
                                                  <td>
                                                    <b>UPload File</b>
                                                  </td>
                                                  <td>
                                                    {filteredSubItem.shipperFile ? (
                                                      <img
                                                        src={`${PIC.PIC_URL}${filteredSubItem.shipperFile}`}
                                                        alt="Owner Image"
                                                        style={{
                                                          width: 40,
                                                          height: 40,
                                                        }}
                                                      />
                                                    ) : null}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* Render other properties of owner as needed */}
                                            </tr>
                                          ) : (
                                            ""
                                          )}
                                        </tbody>
                                      </Table>
                                    ))}
                                </React.Fragment>
                              ))}
                            </Table>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}

                  {isOpenConsignee == true || isOpenConsigneeObject == true ? (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Consignee</Accordion.Header>
                      <Accordion.Body>
                        <Table bordered>
                          <tbody>
                            {/* {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "Shipper") // Filter by userType "Shipper"
                                .map((item, index) => {

                                  item.name.map((item,indexs)=>{
                                    return (
                                      <tr key={indexs}>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>West Coast Shipping Co.</td>
                                      </tr>
                                    );
                                  })
                                  
                                })} */}
                            {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "Consignee") // Filter by userType "Shipper"
                                .map((item, index) => (
                                  <React.Fragment key={index}>
                                    {Array.isArray(item.name) ? ( // Check if name is an array
                                      item.name.map((nameObject, nameIndex) => (
                                        <tr key={nameIndex}>
                                          <td>
                                            <b>Shipper Name</b>
                                          </td>
                                          <td>
                                            {typeof nameObject === "string"
                                              ? nameObject
                                              : nameObject.name}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      // Handle the case when name is not an array
                                      <tr>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>
                                          {typeof item.name === "string"
                                            ? item.name
                                            : item.name["sdfd"]}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                          </tbody>
                        </Table>

                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          {data.length > 0 &&
                          data[0].screeningType === "Batch Screening" ? (
                            data.map((item, index) => (
                              <React.Fragment key={index}>
                                {item.screeningRpsData
                                  .filter(
                                    (subItem) =>
                                      subItem.userType === "Consignee"
                                  )
                                  .map((filteredSubItem, subIndex) => (
                                    <Table key={subIndex} bordered>
                                      <tbody>
                                        {filteredSubItem.Owners_name.map(
                                          (owner, ownerIndex) => (
                                            <tr key={ownerIndex}>
                                              {owner.name ? (
                                                <>
                                                  <td>
                                                    <b>Owner Name</b>
                                                  </td>
                                                  <td>{owner.name}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.ShipperRisk ? (
                                                <>
                                                  <td>
                                                    <b>Shipper Risk </b>
                                                  </td>
                                                  <td>{owner.ShipperRisk}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.Shipper_Owners_Address ? (
                                                <>
                                                  <td>
                                                    <b>
                                                      Shipper Owners Address
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {
                                                      owner.Shipper_Owners_Address
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankAddress ? (
                                                <>
                                                  <td>
                                                    <b>Bank Address </b>
                                                  </td>
                                                  <td>{owner.bankAddress}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankCheck ? (
                                                <>
                                                  <td>
                                                    <b>Bank Check</b>
                                                  </td>
                                                  <td>{owner.bankCheck}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankName ? (
                                                <>
                                                  <td>
                                                    <b>Bank Name</b>
                                                  </td>
                                                  <td>{owner.bankName}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperDOBs ? (
                                                <>
                                                  <td>
                                                    <b>Shipper DOB</b>
                                                  </td>
                                                  <td>{owner.shipperDOBs}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperfile ? (
                                                <>
                                                  <td>
                                                    <b>UPload File</b>
                                                  </td>
                                                  <td>
                                                    {owner.shipperfile ? (
                                                      <img
                                                        src={`${PIC.PIC_URL}${owner.shipperfile}`}
                                                        alt="Owner Image"
                                                        style={{
                                                          width: 40,
                                                          height: 40,
                                                        }}
                                                      />
                                                    ) : null}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* Render other properties of owner as needed */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  ))}
                              </React.Fragment>
                            ))
                          ) : (
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Owner Name</b>
                                  </td>
                                  <td>{"owner.name"}</td>
                                  {/* Render other properties of owner as needed */}
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}

                  {isOpenEndUserString == true ||
                  isOpenEndUserObject == true ? (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>End User</Accordion.Header>
                      <Accordion.Body>
                        <Table bordered>
                          <tbody>
                            {/* {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "Shipper") // Filter by userType "Shipper"
                                .map((item, index) => {

                                  item.name.map((item,indexs)=>{
                                    return (
                                      <tr key={indexs}>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>West Coast Shipping Co.</td>
                                      </tr>
                                    );
                                  })
                                  
                                })} */}
                            {data.length > 0 &&
                              data[0].screeningRpsData
                                .filter((item) => item.userType === "End User") // Filter by userType "Shipper"
                                .map((item, index) => (
                                  <React.Fragment key={index}>
                                    {Array.isArray(item.name) ? ( // Check if name is an array
                                      item.name.map((nameObject, nameIndex) => (
                                        <tr key={nameIndex}>
                                          <td>
                                            <b>Shipper Name</b>
                                          </td>
                                          <td>
                                            {typeof nameObject === "string"
                                              ? nameObject
                                              : nameObject.name}
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      // Handle the case when name is not an array
                                      <tr>
                                        <td>
                                          <b>Shipper Name</b>
                                        </td>
                                        <td>
                                          {typeof item.name === "string"
                                            ? item.name
                                            : item.name["sdfd"]}
                                        </td>
                                      </tr>
                                    )}
                                  </React.Fragment>
                                ))}
                          </tbody>
                        </Table>

                        <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                          {data.length > 0 &&
                          data[0].screeningType === "Batch Screening" ? (
                            data.map((item, index) => (
                              <React.Fragment key={index}>
                                {item.screeningRpsData
                                  .filter(
                                    (subItem) => subItem.userType === "End User"
                                  )
                                  .map((filteredSubItem, subIndex) => (
                                    <Table key={subIndex} bordered>
                                      <tbody>
                                        {filteredSubItem.Owners_name.map(
                                          (owner, ownerIndex) => (
                                            <tr key={ownerIndex}>
                                              {owner.name ? (
                                                <>
                                                  <td>
                                                    <b>Owner Name</b>
                                                  </td>
                                                  <td>{owner.name}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.ShipperRisk ? (
                                                <>
                                                  <td>
                                                    <b>Shipper Risk </b>
                                                  </td>
                                                  <td>{owner.ShipperRisk}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.Shipper_Owners_Address ? (
                                                <>
                                                  <td>
                                                    <b>
                                                      Shipper Owners Address
                                                    </b>
                                                  </td>
                                                  <td>
                                                    {
                                                      owner.Shipper_Owners_Address
                                                    }
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankAddress ? (
                                                <>
                                                  <td>
                                                    <b>Bank Address </b>
                                                  </td>
                                                  <td>{owner.bankAddress}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankCheck ? (
                                                <>
                                                  <td>
                                                    <b>Bank Check</b>
                                                  </td>
                                                  <td>{owner.bankCheck}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.bankName ? (
                                                <>
                                                  <td>
                                                    <b>Bank Name</b>
                                                  </td>
                                                  <td>{owner.bankName}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperDOBs ? (
                                                <>
                                                  <td>
                                                    <b>Shipper DOB</b>
                                                  </td>
                                                  <td>{owner.shipperDOBs}</td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {owner.shipperfile ? (
                                                <>
                                                  <td>
                                                    <b>UPload File</b>
                                                  </td>
                                                  <td>
                                                    {owner.shipperfile ? (
                                                      <img
                                                        src={`${PIC.PIC_URL}${owner.shipperfile}`}
                                                        alt="Owner Image"
                                                        style={{
                                                          width: 40,
                                                          height: 40,
                                                        }}
                                                      />
                                                    ) : null}
                                                  </td>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {/* Render other properties of owner as needed */}
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    </Table>
                                  ))}
                              </React.Fragment>
                            ))
                          ) : (
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>
                                    <b>Owner Name</b>
                                  </td>
                                  <td>{"owner.name"}</td>
                                  {/* Render other properties of owner as needed */}
                                </tr>
                              </tbody>
                            </Table>
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}

                  {/* {isOpenConsignee == true || isOpenConsigneeObject == true ? (
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Consignee</Accordion.Header>
                      <Accordion.Body>
                        <Table bordered>
                          <tbody>
                            <tr>
                              <td>
                                <b>Consignee Name</b>
                              </td>
                              <td>West Coast Shipping Co.</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )}

                  {isOpenEndUserString == true ||
                  isOpenEndUserObject == true ? (
                    <Accordion.Item eventKey="3">
                      <Accordion.Header>End User</Accordion.Header>
                      <Accordion.Body>
                        <Table bordered>
                          <tbody>
                            <tr>
                              <td>
                                <b>End User Name</b>
                              </td>
                              <td>Accel</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Accordion.Body>
                    </Accordion.Item>
                  ) : (
                    ""
                  )} */}
                </Accordion>
              </div>
            </Tab>

            <Tab eventKey="tab2" title="HS Code Verification">
              <div className="verificationtab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>HS Code Search</th>
                      <th>Goods Description</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data[0].hsCodeVerificationData.length > 0 &&
                      data[0].hsCodeVerificationData.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.hscodeType.map((hscode, hsIndex) => (
                            <tr key={hsIndex}>
                              <td>{hscode.hsCode}</td>
                              <td>{hscode.goodDescription}</td>
                              <td>{hscode.chemicalDangerios}</td>
                              <td>{hscode.DualUseExport}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="tab3" title="HS Code Sanctions">
              <div className="hscodetab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS Code or Taric Code (EU)</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                      <th>Any Trans-shipment?</th>
                      <th>Trans-shipment Description</th>
                      <th>Any Re-Export?</th>
                      <th>Trans-shipment Destination</th>
                      <th>Goods Description</th>
                      <th>ECCN/Taric-DU#</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data[0].hsCodeVerificationData.length > 0 &&
                      data[0].hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.goodDescription}</td>
                            ))}

                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.hsCode}</td>
                            ))}
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.chemicalDangerios}</td>
                            ))}
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.DualUseExport}</td>
                            ))}
                            <td>{item.anyTransShipment}</td>
                            <td>
                              {item.TrandestinationDesription
                                ? item.TrandestinationDesription
                                : ""}
                            </td>

                            <td>{item.anyReexport}</td>
                            {/* <td>{item.TransShipmentDesription?item.TransShipmentDesription:""}</td> */}
                            {countryDeatil.map((hscode, hsIndex) => (
                              <td key={hsIndex}>
                                {hscode.transhipment.length > 0
                                  ? hscode.transhipment[0].country
                                  : ""}
                              </td>
                            ))}

                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.goodDescription}</td>
                            ))}

                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.EccN}</td>
                            ))}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="tab4" title="Dual-Use Goods">
              <div className="gooddualtab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                      <th>Is Precursor Chemicals?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data[0].hsCodeVerificationData.length > 0 &&
                      data[0].hsCodeVerificationData.map((item, index) => {
                        return (
                          <tr key={index}>
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.goodDescription}</td>
                            ))}

                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.hsCode}</td>
                            ))}
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.chemicalDangerios}</td>
                            ))}
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.DualUseExport}</td>
                            ))}
                            {item.hscodeType.map((hscode, hsIndex) => (
                              <td key={hsIndex}>{hscode.Precursor}</td>
                            ))}
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="tab5" title="Re-export Country Control">
              <div className="Re-exporttab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th rowSpan={2}>Country</th>
                      <th colSpan={3} className="text-center">
                        Chemical and biological weapons
                      </th>
                      <th colSpan={2} className="text-center">
                        Nuclear Non Proliferation
                      </th>
                      <th colSpan={2} className="text-center">
                        National Security
                      </th>
                      <th className="text-center">Missile Tech</th>
                      <th colSpan={2} className="text-center">
                        Regional Stability
                      </th>
                      <th className="text-center">Firearms Convention</th>
                    </tr>
                    <tr>
                      <th className="text-center">CB1</th>
                      <th className="text-center">CB2</th>
                      <th className="text-center">CB3</th>
                      <th className="text-center">NP1</th>
                      <th className="text-center">NP2</th>
                      <th className="text-center">NS1</th>
                      <th className="text-center">NS2</th>
                      <th className="text-center">MT1</th>
                      <th className="text-center">RS1</th>
                      <th className="text-center">RS2</th>
                      <th className="text-center">FC1</th>
                    </tr>
                  </thead>
                  <tbody>
                    {countryDeatil.length > 0 &&
                    countryDeatil[0].transhipment.length > 0
                      ? countryDeatil[0].transhipment.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.country}</td>
                              <td className="text-center">
                                {item.CB1 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB2 == "1" ? "Yes" : "No"}
                              </td>

                              <td className="text-center">
                                {item.CB3 == "1" ? "Yes" : "No"}
                              </td>

                              <td className="text-center">
                                {item.CB4 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB5 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB6 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB7 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB8 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB9 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB10 == "1" ? "Yes" : "No"}
                              </td>
                              <td className="text-center">
                                {item.CB11 == "1" ? "Yes" : "No"}
                              </td>
                            </tr>
                          );
                        })
                      : ""}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="tab6" title="Dangerous Goods">
              <div className="gooddualtab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                      <th>HAZCHEM Class</th>
                      <th>Label</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data[0].hsCodeVerificationData.length > 0 &&
                      data[0].hsCodeVerificationData.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.hscodeType.map((hscode, hsIndex) => (
                            <tr key={hsIndex}>
                              <td>{hscode.goodDescription}</td>

                              <td>{hscode.hsCode}</td>
                              <td>{hscode.chemicalDangerios}</td>
                              <td>{hscode.DualUseExport}</td>
                              <td>
                                {hscode.HAZCHEM
                                  ? hscode.HAZCHEM.goodDescription
                                  : ""}
                              </td>
                              {hscode.HAZCHEM && hscode.HAZCHEM.Pic != null ? (
                                <td>
                                  <img
                                    className="screenstatus_icon"
                                    src={`${PIC.PIC_URL}${hscode.HAZCHEM.Pic}`}
                                    style={{ width: 80, height: 80 }}
                                  />
                                </td>
                              ) : (
                                ""
                              )}
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    {/* <tr>
                      <td>Hands Free 778 TY</td>
                      <td>345678</td>
                      <td>Yes</td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>
                        <img
                          className="screenstatus_icon"
                          src={badgecircle_icon}
                        />
                      </td>
                    </tr> */}
                    {/* <tr>
                      <td>Hands Free 778 TY</td>
                      <td>345678</td>
                      <td>Yes</td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>
                        <img
                          className="screenstatus_icon"
                          src={badgecircle_icon}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Hands Free 778 TY</td>
                      <td>345678</td>
                      <td>Yes</td>
                      <td>No</td>
                      <td>Yes</td>
                      <td>
                        <img
                          className="screenstatus_icon"
                          src={badgecircle_icon}
                        />
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </Tab>
            <Tab eventKey="tab7" title="Precursor Chemicals">
              <div className="gooddualtab_div mt-4">
                <Table bordered responsive>
                  <thead>
                    <tr>
                      <th>Goods Description</th>
                      <th>HS code or Taric Code (EU)</th>
                      <th>Chemical Or Dangerous Good</th>
                      <th>Dual Use Export Controlled Goods?</th>
                      <th>Is Precursor Chemicals?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 &&
                      data[0].hsCodeVerificationData.length > 0 &&
                      data[0].hsCodeVerificationData.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.hscodeType.map((hscode, hsIndex) => (
                            <tr key={hsIndex}>
                              <td>{hscode.goodDescription}</td>

                              <td>{hscode.hsCode}</td>
                              <td>{hscode.chemicalDangerios}</td>
                              <td>{hscode.DualUseExport}</td>
                              <td>{hscode.Precursor}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
          {!key && (
            <Row className="mt-5">
              {data.length > 0 &&
              data[0].screeningType === "Batch Screening" ? (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.screeningRpsData
                      .filter((subItem) => subItem.userType === "Shipper")
                      .map((filteredSubItem, subIndex) => (
                        <Col md={6}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (Shipper)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "Shipper"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                          <React.Fragment>
                                           
                                              {filteredSubItem.Owners_name
                                                .length > 0 ? (
                                                <tbody key={subIndex}>
                                                  {filteredSubItem.repound.some(
                                                    (repoundItem) =>
                                                      repoundItem.risk &&
                                                      repoundItem.risk !== ""
                                                  )
                                                    ? filteredSubItem.repound.map(
                                                        (
                                                          repoundItem,
                                                          repoundIndex
                                                        ) =>
                                                          // Check if "risk" exists and is not empty in repoundItem
                                                          repoundItem.risk &&
                                                          repoundItem.risk !==
                                                            "" && (
                                                            <tr>
                                                              <td>
                                                                {
                                                                  repoundItem.ShipperName
                                                                }
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "no"
                                                                      ? checkcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "yes"
                                                                      ? riskcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              </tr>

                                                            // <tr key={repoundIndex}>
                                                            //   <td>
                                                            //     <b>Shipper Risk</b>
                                                            //   </td>
                                                            //   <td>
                                                            //     {repoundItem.risk}
                                                            //   </td>
                                                            // </tr>
                                                          )
                                                      )
                                                    : ""}

                                                  {/* Render other properties of owner as needed */}
                                                </tbody>
                                              ) : (
                                                ""
                                              )}
                                              
                                           
                                            </React.Fragment  >
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                              <td>EU Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={riskcircle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>USA Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>UN Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={checkcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {data.length > 0 &&
                    data
                      .filter((item) =>
                        item.screeningRpsData.some(
                          (subItem) => subItem.userType === "Shipper"
                        )
                      )
                      .map((item, index) => (
                        <Col md={6} key={index}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (Shipper)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "Shipper"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                          
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {filteredSubItem.name.length >
                                                  0
                                                    ? filteredSubItem.name[0]
                                                    : ""}
                                                </td>

                                                {filteredSubItem.repound.every(
                                                  (repoundItem) =>
                                                    repoundItem.ShipperName ===
                                                      "" &&
                                                    repoundItem.risk === ""
                                                ) ? (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={checkcircle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={riskcircle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </tbody>
                                    
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                            <td>EU Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={riskcircle_icon}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>USA Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={alertcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>UN Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={checkcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={alertcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                          </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                </>
              )}

              {data.length > 0 &&
              data[0].screeningType === "Batch Screening" ? (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.screeningRpsData
                      .filter((subItem) => subItem.userType === "Consignee")
                      .map((filteredSubItem, subIndex) => (
                        <Col md={6}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (Consignee)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "Consignee"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                        
                                          <React.Fragment>
                                              {filteredSubItem.Owners_name
                                                .length > 0 ? (
                                                <tbody key={subIndex}>
                                                  {filteredSubItem.repound.some(
                                                    (repoundItem) =>
                                                      repoundItem.risk &&
                                                      repoundItem.risk !== ""
                                                  )
                                                    ? filteredSubItem.repound.map(
                                                        (
                                                          repoundItem,
                                                          repoundIndex
                                                        ) =>
                                                          // Check if "risk" exists and is not empty in repoundItem
                                                          repoundItem.risk &&
                                                          repoundItem.risk !==
                                                            "" && (
                                                            <tr>
                                                              <td>
                                                                {
                                                                  repoundItem.ShipperName
                                                                }
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "no"
                                                                      ? checkcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "yes"
                                                                      ? riskcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                            </tr>

                                                            // <tr key={repoundIndex}>
                                                            //   <td>
                                                            //     <b>Shipper Risk</b>
                                                            //   </td>
                                                            //   <td>
                                                            //     {repoundItem.risk}
                                                            //   </td>
                                                            // </tr>
                                                          )
                                                      )
                                                    : ""}

                                                  {/* Render other properties of owner as needed */}
                                                </tbody>
                                              ) : (
                                                ""
                                              )}
                                            </React.Fragment>
                                
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                              <td>EU Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={riskcircle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>USA Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>UN Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={checkcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {data.length > 0 &&
                    data
                      .filter((item) =>
                        item.screeningRpsData.some(
                          (subItem) => subItem.userType === "Consignee"
                        )
                      )
                      .map((item, index) => (
                        <Col md={6} key={index}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (Consignee)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "Consignee"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                        
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {filteredSubItem.name.length >
                                                  0
                                                    ? filteredSubItem.name[0]
                                                    : ""}
                                                </td>

                                                {filteredSubItem.repound.every(
                                                  (repoundItem) =>
                                                    repoundItem.ShipperName ===
                                                      "" &&
                                                    repoundItem.risk === ""
                                                ) ? (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={checkcircle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={riskcircle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </tbody>
                                       
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                            <td>EU Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={riskcircle_icon}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>USA Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={alertcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>UN Sanctions Results</td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={checkcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={alertcircle_icon}
                                              />
                                            </td>
                                            <td className="text-center">
                                              <img
                                                className="screenstatus_icon"
                                                src={circle_icon}
                                              />
                                            </td>
                                          </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                </>
              )}
              {data.length > 0 &&
              data[0].screeningType === "Batch Screening" ? (
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    {item.screeningRpsData
                      .filter((subItem) => subItem.userType === "End User")
                      .map((filteredSubItem, subIndex) => (
                        <Col md={6}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (End User)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "End User"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                          
                                            <React.Fragment>
                                              {filteredSubItem.Owners_name
                                                .length > 0 ? (
                                                <tbody key={subIndex}>
                                                  {filteredSubItem.repound.some(
                                                    (repoundItem) =>
                                                      repoundItem.risk &&
                                                      repoundItem.risk !== ""
                                                  )
                                                    ? filteredSubItem.repound.map(
                                                        (
                                                          repoundItem,
                                                          repoundIndex
                                                        ) =>
                                                          // Check if "risk" exists and is not empty in repoundItem
                                                          repoundItem.risk &&
                                                          repoundItem.risk !==
                                                            "" && (
                                                           <tr>
                                                              <td>
                                                                {
                                                                  repoundItem.ShipperName
                                                                }
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "no"
                                                                      ? checkcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              <td className="text-center">
                                                                <img
                                                                  className="screenstatus_icon"
                                                                  src={
                                                                    repoundItem.status ==
                                                                    "yes"
                                                                      ? riskcircle_icon
                                                                      : circle_icon
                                                                  }
                                                                />
                                                              </td>
                                                              </tr>

                                                            // <tr key={repoundIndex}>
                                                            //   <td>
                                                            //     <b>Shipper Risk</b>
                                                            //   </td>
                                                            //   <td>
                                                            //     {repoundItem.risk}
                                                            //   </td>
                                                            // </tr>
                                                          )
                                                      )
                                                    : ""}

                                                  {/* Render other properties of owner as needed */}
                                                </tbody>
                                              ) : (
                                                ""
                                              )}
                                            </React.Fragment>
                                        
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                              <td>EU Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={riskcircle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>USA Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr>
                                            <tr>
                                              <td>UN Sanctions Results</td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={checkcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={alertcircle_icon}
                                                />
                                              </td>
                                              <td className="text-center">
                                                <img
                                                  className="screenstatus_icon"
                                                  src={circle_icon}
                                                />
                                              </td>
                                            </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </React.Fragment>
                ))
              ) : (
                <>
                  {data.length > 0 &&
                    data
                      .filter((item) =>
                        item.screeningRpsData.some(
                          (subItem) => subItem.userType === "End User"
                        )
                      )
                      .map((item, index) => (
                        <Col md={6} key={index}>
                          <div className="screeningcard_table">
                            <div className="screencard_head">
                              <h3>RPS (End User)</h3>
                            </div>
                            <div className="screencard_body">
                              <Table>
                                <thead>
                                  <tr>
                                    <th></th>
                                    <th className="text-center">No Risk</th>
                                    <th className="text-center">Alert</th>
                                    <th className="text-center">Risk</th>
                                  </tr>
                                </thead>

                                {data.length > 0 &&
                                  data.map((item, index) => (
                                    <React.Fragment key={index}>
                                      {item.screeningRpsData
                                        .filter(
                                          (subItem) =>
                                            subItem.userType === "End User"
                                        )
                                        .map((filteredSubItem, subIndex) => (
                                       
                                            <tbody>
                                              <tr>
                                                <td>
                                                  {filteredSubItem.name.length >
                                                  0
                                                    ? filteredSubItem.name[0]
                                                    : ""}
                                                </td>

                                                {filteredSubItem.repound.every(
                                                  (repoundItem) =>
                                                    repoundItem.ShipperName ===
                                                      "" &&
                                                    repoundItem.risk === ""
                                                ) ? (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={checkcircle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                ) : (
                                                  <>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={circle_icon}
                                                      />
                                                    </td>
                                                    <td className="text-center">
                                                      <img
                                                        className="screenstatus_icon"
                                                        src={riskcircle_icon}
                                                      />
                                                    </td>
                                                  </>
                                                )}
                                              </tr>
                                            </tbody>
                                      
                                        ))}
                                    </React.Fragment>
                                  ))}

                                {/* <tr>
                                          <td>EU Sanctions Results</td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={circle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={circle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={riskcircle_icon}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>USA Sanctions Results</td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={circle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={alertcircle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={circle_icon}
                                            />
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>UN Sanctions Results</td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={checkcircle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={alertcircle_icon}
                                            />
                                          </td>
                                          <td className="text-center">
                                            <img
                                              className="screenstatus_icon"
                                              src={circle_icon}
                                            />
                                          </td>
                                        </tr> */}
                              </Table>
                            </div>
                          </div>
                        </Col>
                      ))}
                </>
              )}
              <Col md={6}>
                <div className="screeningcard_table">
                  <div className="screencard_head">
                    <h3>HS Code Sanctions</h3>
                  </div>
                  <div className="screencard_body">
                    <Table>
                      <thead>
                        <tr>
                          <th></th>
                          <th></th>
                          <th className="text-center">No Risk</th>
                          <th className="text-center">Alert</th>
                          <th className="text-center">Risk</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data[0].hsCodeVerificationData.length > 0 &&
                          data[0].hsCodeVerificationData.map((item, index) => (
                            <React.Fragment key={index}>
                              {item.hscodeType.map((hscode, hsIndex) => (
                                <tr key={hsIndex}>
                                  <td>{hscode.goodDescription}</td>
                                  <td className="text-center">
                                    {hscode.hsCode}
                                  </td>

                                  <td className="text-center">
                                    <img
                                      className="screenstatus_icon"
                                      src={
                                        hscode.status == "no"
                                          ? checkcircle_icon
                                          : circle_icon
                                      }
                                    />
                                  </td>
                                  <td className="text-center">
                                    <img
                                      className="screenstatus_icon"
                                      src={circle_icon}
                                    />
                                  </td>
                                  <td className="text-center">
                                    <img
                                      className="screenstatus_icon"
                                      src={
                                        hscode.hsCode.status == "yes"
                                          ? riskcircle_icon
                                          : circle_icon
                                      }
                                    />
                                  </td>
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}

                        {/* <tr>
                          <td>JKLU654LP</td>
                          <td className="text-center">6451</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={checkcircle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={circle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={circle_icon}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>JKLU654LP</td>
                          <td className="text-center">9874</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={circle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={circle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={riskcircle_icon}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>POES654HD</td>
                          <td className="text-center">3654</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={checkcircle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={alertcircle_icon}
                            />
                          </td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={circle_icon}
                            />
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="screeningcard_table">
                  <div className="screencard_head">
                    <h3>Dangerous Goods</h3>
                  </div>
                  <div className="screencard_body">
                    <Table>
                      <thead>
                        <tr>
                          <th>Goods</th>
                          <th className="text-center">
                            Chemical Or Dangerous Good
                          </th>
                          <th className="text-center">
                            Dual Use Export Controlled Goods
                          </th>
                          <th className="text-center">Hazchem Class Label</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data[0].hsCodeVerificationData.length > 0 &&
                          data[0].hsCodeVerificationData.map((item, index) => (
                            <React.Fragment key={index}>
                              {item.hscodeType.map((hscode, hsIndex) => (
                                <tr key={hsIndex}>
                                  <td>
                                    {hscode.HAZCHEM
                                      ? hscode.HAZCHEM.goodDescription
                                      : ""}
                                  </td>
                                  <td className="text-center">
                                    {hscode.chemicalDangerios
                                      ? hscode.chemicalDangerios
                                      : ""}
                                  </td>
                                  <td className="text-center">
                                    {hscode.DualUseExport
                                      ? hscode.DualUseExport
                                      : ""}
                                  </td>
                                  {hscode.HAZCHEM &&
                                  hscode.HAZCHEM.Pic != null ? (
                                    <td className="text-center">
                                      <img
                                        className="screenstatus_icon"
                                        src={`${PIC.PIC_URL}${hscode.HAZCHEM.Pic}`}
                                        style={{ width: 80, height: 80 }}
                                        // src={badgecircle_icon}
                                      />
                                    </td>
                                  ) : (
                                    ""
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}

                        {/* <tr>
                          <td>Hands Free 778 TY</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={badgecircle_icon}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Hands Free 778 TY</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={badgecircle_icon}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Hands Free 778 TY</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">Yes</td>
                          <td className="text-center">
                            <img
                              className="screenstatus_icon"
                              src={badgecircle_icon}
                            />
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col md={6}>
                <div className="screeningcard_table">
                  <div className="screencard_head">
                    <h3>Dual-Use Re-Export & C.I.</h3>
                  </div>
                  <div className="screencard_body">
                    <Table>
                      <thead>
                        <tr>
                          <th>Goods</th>
                          <th className="text-center">ECCN or EU DUXXX# </th>
                          <th className="text-center">No Licence Required??</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 &&
                          data[0].hsCodeVerificationData.length > 0 &&
                          data[0].hsCodeVerificationData.map((item, index) => (
                            <React.Fragment key={index}>
                              {item.hscodeType.map((hscode, hsIndex) => (
                                <tr key={index}>
                                  <td>
                                    {hscode.goodDescription
                                      ? hscode.goodDescription
                                      : ""}
                                  </td>
                                  <td className="text-center">
                                    {hscode.EccN ? hscode.EccN : ""}
                                  </td>
                                  {hscode.licines == "Yes" ? (
                                    <td className="text-center">
                                      <Icon
                                        icon="ic:baseline-check"
                                        style={{
                                          color: "#008000",
                                          fontSize: "18px",
                                        }}
                                      ></Icon>
                                    </td>
                                  ) : (
                                    <td className="text-center">
                                      <Icon
                                        icon="ic:baseline-check"
                                        style={{
                                          color: "#008000",
                                          fontSize: "18px",
                                        }}
                                      ></Icon>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </React.Fragment>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
