import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Row, Col, Button } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import { Link } from "react-router-dom";
import * as SERVICE from "../../services/index";
import * as API from "../../services/env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Login() {
  const navigate = useNavigate();

  const [email, setemail] = useState("");
  const [errorCount, seterrCount] = useState(0);

  const [password, setpassword] = useState("");
  const [passworderr, setpassworderr] = useState("");

  const [confirmPass, setconfirmPass] = useState("");
  const [confirmPasserr, setconfirmPasserr] = useState("");



  const [Submit, setSubmit] = useState(false);

  const onProfileValidation = async () => {
    let errorCount = 0;

    if (SERVICE.hasError(password)) {
      errorCount++;
      let msg = "Please enter your password.";
      setpassworderr(msg);
    } else {
      setpassworderr(null);
    }

    if (SERVICE.hasError(confirmPass)) {
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPasserr(msg);
    } else {
      setconfirmPasserr(null);
    }

    
    if (SERVICE.hasError(confirmPass)) {
      errorCount++;
      let msg = "Please re-enter your password.";
      setconfirmPasserr(msg);
    } else {
      setconfirmPasserr(null);
    }

    if (errorCount > 0) return seterrCount(errorCount);
    else seterrCount(0);
  };

  const onSubmit = async () => {
    setSubmit(true);
    onProfileValidation();
    if (errorCount > 0) return;
    // await detectWhitespace(domain.trim());

    let payload = {
      email: email,
      newPassword: password,
      confirmnewpassword: confirmPass,
    };

    console.log("payload", payload);

    await fetch(`${API.BASE_URL}AdminresetPasswords`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        email: email,
        newPassword: password,
        confirmnewpassword: confirmPass,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          toast("Password reset instruction sent on your email address.");
          window.location.href = "/";
        } else if (res.status == false) {
          toast(res.msg);
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    // Get the current URL
    const currentUrl = window.location.href;

    // Split the URL by '/'
    const urlParts = currentUrl.split("/");

    // Get the last part of the URL, which is the user ID
    const lastPart = urlParts[urlParts.length - 1];

    console.log("lastPart", lastPart);

    fetch(`${API.BASE_URL}fetchAdminDetail`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      // mode: "cors",
      body: JSON.stringify({
        token: lastPart,
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((res) => {
        if (res.status == true) {
          setemail(res.data[0].email);
          // window.location.href = "/accel/login";
        } else {
        }
      })
      .catch((error) => {});
    // Add your logic for handling the last ID here
  }, []);

  useEffect(() => {
    onProfileValidation();
  }, [password, confirmPass]);
  return (
    <React.Fragment>
      <Row>
        <Col lg={6} className="loginform">
          <div className="loginmain_div maxwidth500 mx-auto">
            <div className="headlogin_div mb-4">
              <h2>Reset Password</h2>
              <p>Please enter the details below to reset your password.</p>
            </div>
            <Form className="">
              <Form.Group className="mb-4 form-group">
                <Form.Label>New password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter new password"
                  onChange={(e) => setpassword(e.target.value)} // set the onChange handler
                  value={password}
                />
                {passworderr && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {passworderr}
                  </div>
                ) : null}
              </Form.Group>
              <Form.Group className="mb-4 form-group">
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter confirm new password"
                  onChange={(e) => setconfirmPass(e.target.value)} // set the onChange handler
                  value={confirmPass}
                />
                {confirmPasserr && Submit ? (
                  <div
                    style={{
                      fontFamily: "AvenirLTProLight",
                      color: "#FF0000",
                      opacity: 0.6,
                    }}
                  >
                    {confirmPasserr}
                  </div>
                ) : null}
              </Form.Group>
              <Button
                as="input"
                value="Reset Password"
                className="btn btn-primary w-100"
                onClick={onSubmit}
              />
              {/* <Link to="/"><Button as="input" type="submit" value="Reset Password" className="btn btn-primary w-100"/></Link> */}
              <p className="formfooter text-center mt-3">
                Back To <Link to="/">Login</Link>
              </p>
            </Form>
          </div>
        </Col>
        <Col lg={6}>
        <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div>
          <ToastContainer />
          {/* <div className="loginlogo_div">
            <Link>
              <img src={logo} alt="" />
            </Link>
          </div> */}
        </Col>
      </Row>
    </React.Fragment>
  );
}
